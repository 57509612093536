import {FormGroup} from '@angular/forms'
import {
  IApplication,
  LLApplicant,
  OccupationType
} from '@sparbanken-syd/loan-backend'
import {PropertyType} from '@sparbanken-syd/loan-backend/dist/types'
import {STORED_APPLICATION_NAME} from '../application/data-types'
import {PreFillOption, RemoveOption} from '../common/header/header.component'
import {ILanelofteForm} from '../loan/lanelafte/lanelafte.component'
import mockApplication from '../loan/lanelafte/mock-application.json'
import {UcMockType} from '../services/loan.service'

export class KalpUtils {
  public static removeDataFromForm(option: RemoveOption, form: FormGroup<ILanelofteForm>, localStorage: Storage) {
    switch (option) {
      case RemoveOption.APPLICATION:
        localStorage.removeItem(STORED_APPLICATION_NAME)
        form.reset()
        break
      case RemoveOption.PERSONAL_NUMBER:
        form.controls.applicantsData.controls.applicants.controls.forEach(applicantGroup =>
          applicantGroup.controls.personalNumber.setValue(''))
        break
      case RemoveOption.PROPERTY:
        form.controls.properties.reset()
        break
    }
  }

  public static preFillFormData(option: PreFillOption, form: FormGroup<ILanelofteForm>) {
    // Reset actual form
    form.reset()

    const application = JSON.parse(JSON.stringify(mockApplication))
    switch (option) {
      case PreFillOption.APPROVED:
        application.contactsData.contacts[0].fullName = 'All Is Approved'
        break
      case PreFillOption.DENY_UC:
        application.contactsData.contacts[0].fullName = 'Rejected Deny UC'
        break
      case PreFillOption.DENY_KALP:
        application.contactsData.contacts[0].fullName = 'Rejected Deny KALP'
        // Calculate a price that will be denied by KALP
        application.applicantIncomesData.applicantIncomes.forEach((a: any) => a.monthlyIncome = 1000)
        break
      case PreFillOption.DENY_INCOME:
        application.contactsData.contacts[0].fullName = 'Rejected Deny Income'
        // Set an income much bigger than the one provided by UC
        application.applicantIncomesData.applicantIncomes.forEach((a: any) => a.monthlyIncome = 100000)
        break
      case PreFillOption.NON_SUPERFAST_OCCUPATION:
        application.contactsData.contacts[0].fullName = 'NonSuperfast Wrong Occupation'
        application.applicantIncomesData.applicantIncomes[0].occupation = OccupationType.HOURLY
        break
      case PreFillOption.NON_SUPERFAST_OLD_PROPERTY:
        application.contactsData.contacts[0].fullName = 'NonSuperfast Wrong OldProperty'
        application.properties.oldProperty.futurePropertyOwnership = 'intendToSell'
        application.properties.oldProperty.loansAmount = 456254
        application.properties.oldProperty.sellingPrice = 12345
        application.properties.oldProperty.type = PropertyType.BOSTADSRATT
        application.properties.oldProperty.fee = 55555
        break
      case PreFillOption.NON_SUPERFAST_ALL_REASONS:
        application.contactsData.contacts[0].fullName = 'NonSuperfast All Reasons'
        application.applicantIncomesData.applicantIncomes[0].occupation = OccupationType.HOURLY
        application.applicantIncomesData.applicantIncomes[1].occupation = OccupationType.TIME_LIMITED
        application.properties.oldProperty.futurePropertyOwnership = 'intendToSell'
        application.properties.oldProperty.loansAmount = 456254
        application.properties.oldProperty.sellingPrice = 12345
        application.properties.oldProperty.type = PropertyType.BOSTADSRATT
        application.properties.oldProperty.fee = 55555
        break
    }

    // Set the new value
    setTimeout(() => {
      form.setValue(application)
    }, 100)
  }

  public static addMockUc(type: UcMockType, application: Partial<IApplication>): void {
    application.applicants?.forEach((a: LLApplicant) => {
      a.uc = {
        municipalityCode: '12',
        countyCode: '12',
        decision: type === 'ok' ? 'J' : 'N',
        status: 'approved',
        applicantName: a.contact.name + ' ' + (type === 'ok' ? 'Approved' : 'Rejected'),
        income: ['720000', '650000'],
        reasons: ['Manuellt inmatat resultat'],
        incomeDiff: 0
      }
    })
  }
}