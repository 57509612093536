<form [formGroup]="form">
  <div class="mat-h3">Övriga upplysningar</div>
  <p>Har du någon ytterligare information du vill lämna så går det bra att
    skriva i fältet nedan</p>
  <mat-form-field appearance="outline" class="standard-full-width">
    <mat-label>Meddelande</mat-label>
    <textarea
      formControlName="message"
      matInput
      rows="10">
    </textarea>
  </mat-form-field>
</form>