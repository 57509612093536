{
  "name": "loan-frontend",
  "version": "2.1.2-157-show-applicants-sub.0",
  "scripts": {
    "ng": "ng",
    "install": "cp ./package.json ./src/assets",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "lint": "ng lint",
    "style-lint": "stylelint \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.6",
    "@angular/cdk": "^19.0.5",
    "@angular/common": "^19.0.6",
    "@angular/compiler": "^19.0.6",
    "@angular/core": "^19.0.6",
    "@angular/forms": "^19.0.6",
    "@angular/material": "^19.0.5",
    "@angular/platform-browser": "^19.0.6",
    "@angular/platform-browser-dynamic": "^19.0.6",
    "@angular/router": "^19.0.6",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.7",
    "@angular-eslint/builder": "19.0.2",
    "@angular-eslint/eslint-plugin": "19.0.2",
    "@angular-eslint/eslint-plugin-template": "19.0.2",
    "@angular-eslint/schematics": "19.0.2",
    "@angular-eslint/template-parser": "19.0.2",
    "@angular/cli": "^19.0.7",
    "@angular/compiler-cli": "^19.0.6",
    "@angular/language-service": "^19.0.6",
    "@sparbanken-syd/loan-backend": "^3.6.2",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.2",
    "@types/jasmine": "~5.1.5",
    "@types/jasminewd2": "~2.0.13",
    "@types/node": "^22",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "angular-eslint": "^19.0.2",
    "eslint": "^9.18.0",
    "jasmine-core": "~5.5.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.5.1",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.13.2",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.10.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.6.3",
    "typescript-eslint": "^8.20.0"
  }
}
