import {Routes} from '@angular/router'
import loanRoutes from './loan/loan.routes'

export const routes: Routes = [
  {
    path: 'lanelofte',
    children: loanRoutes
  },
  {
    path: '',
    redirectTo: 'lanelofte',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'lanelofte',
    pathMatch: 'full'
  }
]