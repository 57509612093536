import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {BankIdComponent} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../../environments/environment'
import {PluralPipe} from '../../common/plural.pipe'
import {IApplicantsForm} from '../lanelafte-step-1/lanelafte-step-1.component'

export const getTermsForm = () => new FormGroup(({
  terms: new FormControl(false, {nonNullable: true}),
  manual: new FormControl(false, {nonNullable: true})
} as ITermsForm))

export interface ITermsForm {
  terms: FormControl<boolean>,
  manual: FormControl<boolean>
}

@Component({
  selector: 'spb-lanelafte-step-7',
  templateUrl: './lanelafte-step-7.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss', './lanelafte-step-7.component.scss'],
  imports: [ReactiveFormsModule, MatCheckbox, BankIdComponent, PluralPipe]
})
export class LanelafteStep7Component {
  @Input() form = new FormGroup<ITermsForm>(({} as ITermsForm))

  @Input({required: true}) applicantsForm = new FormGroup<IApplicantsForm>(({} as IApplicantsForm))

  @Input() errorMessage = ''

  @Input() superFast = false

  @Output() signSuccess = new EventEmitter<string>()

  @ViewChild(BankIdComponent, {static: true}) public bankId!: BankIdComponent

  protected readonly environment = environment

  /**
   * Form getters for easy access
   */
  get hasCoApplicant(): boolean {
    return this.applicantsForm.controls.hasCoApplicant.value
  }
}
