import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn
} from '@angular/forms'

export class FormUtils {
  public static addOrRemoveValidation(add: boolean, control: AbstractControl, validators: ValidatorFn[]): void {
    if (add) {
      control.addValidators(validators)
    } else {
      control.setValidators(null)
      control.reset()
    }
    control.updateValueAndValidity({emitEvent: false})
  }

  public static modifyNumberOfGroups<T extends { [K in keyof T]: AbstractControl<any, any>; }>(
    formArray: FormArray<FormGroup<T>>,
    newCount: number,
    createNewGroup: (index: number) => FormGroup<T>
  ) {
    const currentCount = formArray.length

    if (newCount > currentCount) {
      // Add more groups
      for (let i = currentCount; i < newCount; i++) {
        formArray.push(createNewGroup(i))
      }
    } else if (newCount < currentCount) {
      // Remove unwanted groups
      for (let i = currentCount - 1; i >= newCount; i--) {
        formArray.removeAt(i)
      }
    }
  }

  public static getValue<T>(control: FormControl<T | null | undefined>): T | undefined {
    return control.value ?? undefined
  }
}