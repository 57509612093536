{
  "version": 1,
  "applicantsData": {
    "hasCoApplicant": true,
    "areApplicantsMarried": false,
    "numberOfChildren": 1,
    "hasCar": false,
    "numberOfCars": null,
    "hasLeaseCar": false,
    "leaseCarCost": null,
    "hasChildrenCareCost": false,
    "monthlyChildrenCareCost": 0,
    "applicants": [
      {
        "personalNumber": "19830318-8042",
        "isMarried": false,
        "partnerPersonalNumber": null
      },
      {
        "personalNumber": "19840716-3685",
        "isMarried": false,
        "partnerPersonalNumber": null
      }
    ],
    "children": [
      {
        "age": 0.1,
        "id": "1"
      }
    ]
  },
  "applicantIncomesData": {
    "applicantIncomes": [
      {
        "monthlyIncome": 38000,
        "occupation": 0,
        "employer": "Sparbanken Syd",
        "isAKassaMember": true
      },
      {
        "monthlyIncome": 23000,
        "occupation": 0,
        "employer": "Linaf",
        "isAKassaMember": false
      }
    ]
  },
  "properties": {
    "newProperty": {
      "price": 236000,
      "downPayment": 35400,
      "type": 0,
      "fee": null
    },
    "oldProperty": {
      "futurePropertyOwnership": "noSell",
      "loansAmount": null,
      "sellingPrice": null,
      "type": -1,
      "fee": null
    }
  },
  "existentLoans": {
    "studentLoan": 10000,
    "hasOtherLoans": true,
    "numberOfLoans": 1,
    "otherLoans": [
      {
        "amount": 12000,
        "type": 1
      }
    ]
  },
  "contactsData": {
    "contacts": [
      {
        "fullName": "Dexter Svensson",
        "email": "dexter@test.se",
        "phone": "123123123"
      },
      {
        "fullName": "Stina Ulfsson",
        "email": "co@stina.se",
        "phone": "123123123"
      }
    ]
  },
  "otherInformation": {
    "message": null
  },
  "termsData": {
    "terms": true,
    "manual": true
  }
}