import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms'

// Calculated from: x - (x * 0.15) = 200.000
// Meaning that the loan (price - down payment) must be 200K
export const PRICE_MINIMUM = 235295
export const LOAN_MINIMUM = 200 * 1000
export const LOAN_MINIMUM_TEXT = '200 000'
export const DOWN_PAYMENT_MINIMUM_PERCENTAGE = 0.15
export const DOWN_PAYMENT_MINIMUM_PERCENTAGE_TEXT = '15%'
export const DOWN_PAYMENT_MINIMUM = Math.ceil(PRICE_MINIMUM * DOWN_PAYMENT_MINIMUM_PERCENTAGE)

export const loanPromiseValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const price = control.get('price')
  const downPayment = control.get('downPayment')
  if (!price || !downPayment || !price.value || !downPayment.value) {
    return null
  }

  if (downPayment.value / price.value < DOWN_PAYMENT_MINIMUM_PERCENTAGE) {
    downPayment.setErrors({})
    return {
      downPayment: `Kontantinsatsen måste vara minst ${DOWN_PAYMENT_MINIMUM_PERCENTAGE_TEXT} 
      (${Math.ceil(price.value * DOWN_PAYMENT_MINIMUM_PERCENTAGE)})`
    }
  } else {
    downPayment.setErrors(null)
  }

  if (price.value - downPayment.value < LOAN_MINIMUM) {
    price.setErrors({})
    return {
      price: `Lånebelopp måste vara minst ${LOAN_MINIMUM_TEXT} kr`
    }
  } else {
    price.setErrors(null)
  }

  return null
}
