import {KeyValuePipe} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatOption} from '@angular/material/core'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {TFuturePropertyOwnership} from '@sparbanken-syd/loan-backend'
import {
  OldPropertyStatusMap,
  PropertyTypeMap
} from '@sparbanken-syd/loan-backend/dist/shared'
import {PropertyType} from '@sparbanken-syd/loan-backend/dist/types'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {FormUtils} from '../../utils/form.utils'
import {
  DOWN_PAYMENT_MINIMUM,
  DOWN_PAYMENT_MINIMUM_PERCENTAGE,
  LOAN_MINIMUM,
  loanPromiseValidator
} from '../directives/downPayment/LoanPromiseValidator'
import {IApplicantsForm} from '../lanelafte-step-1/lanelafte-step-1.component'

export const getPropertyForm = () => new FormGroup(({
  newProperty: new FormGroup(({
    price: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(LOAN_MINIMUM)]
    }),
    downPayment: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(DOWN_PAYMENT_MINIMUM)]
    }),
    type: new FormControl(PropertyType.VILLA, {nonNullable: true}),
    fee: new FormControl(null)
  } as INewPropertyGroup), {validators: [loanPromiseValidator]}),

  oldProperty: new FormGroup(({
    futurePropertyOwnership: new FormControl(null, Validators.required),
    loansAmount: new FormControl(null),
    sellingPrice: new FormControl(null),
    type: new FormControl(PropertyType.NONE, {nonNullable: true}),
    fee: new FormControl(null)
  } as IOldPropertyGroup))
} as IPropertiesForm))

export interface IPropertiesForm {
  newProperty: FormGroup<INewPropertyGroup>
  oldProperty: FormGroup<IOldPropertyGroup>
}

export interface INewPropertyGroup {
  price: FormControl<number>
  downPayment: FormControl<number>
  type: FormControl<PropertyType>
  fee: FormControl<number | null>
}

export interface IOldPropertyGroup {
  futurePropertyOwnership: FormControl<TFuturePropertyOwnership | null>
  loansAmount: FormControl<number | null>
  sellingPrice: FormControl<number | null>
  type: FormControl<PropertyType>
  fee: FormControl<number | null>
}

interface OldPropertyStatusProperty {
  type: TFuturePropertyOwnership
  description: string
  hasLoansAmount: boolean
  hasSellingPrice: boolean
  hasPropertyType: boolean
  loansLabel?: string
  sellingLabel?: string
}

@Component({
  selector: 'spb-lanelafte-step-3',
  templateUrl: './lanelafte-step-3.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatInput, MatError, MatSelect, MatOption, MatLabel, KeyValuePipe, FormatNumberDirective]
})
export class LanelafteStep3Component implements OnInit {
  @Input() form = new FormGroup<IPropertiesForm>(({} as IPropertiesForm))

  @Input() applicantsForm = new FormGroup<IApplicantsForm>(({} as IApplicantsForm))

  public readonly propertyTypes = PropertyTypeMap

  /**
   * This is needed in order to use the enum inside the template?!
   * There is no end to this nonsense... :-p
   */
  public readonly PropertyType = PropertyType

  // public readonly options: Record<TFuturePropertyOwnership, OldPropertyStatusProperty> = {
  public readonly options: OldPropertyStatusProperty[] = [
    {
      type: 'noSell',
      description: OldPropertyStatusMap.get('noSell') as string,
      hasLoansAmount: false,
      hasSellingPrice: false,
      hasPropertyType: false
    },
    {
      type: 'confirmedContract',
      description: OldPropertyStatusMap.get('confirmedContract') as string,
      hasLoansAmount: true,
      hasSellingPrice: true,
      hasPropertyType: false,
      loansLabel: 'Totala lånebeloppet på lån som löses bort',
      sellingLabel: 'Vad blev försäljningspriset?'
    },
    {
      type: 'intendToSell',
      description: OldPropertyStatusMap.get('intendToSell') as string,
      hasLoansAmount: true,
      hasSellingPrice: true,
      hasPropertyType: true,
      loansLabel: 'Totala lånebeloppet på lån mot bostaden',
      sellingLabel: 'Uppskattat försäljningspris'
    },
    {
      type: 'keepingProperty',
      description: OldPropertyStatusMap.get('keepingProperty') as string,
      hasLoansAmount: true,
      hasSellingPrice: false,
      hasPropertyType: true,
      loansLabel: 'Totala lånebeloppet på lån mot bostaden'
    },
    {
      type: 'rentalLeave',
      description: OldPropertyStatusMap.get('rentalLeave') as string,
      hasLoansAmount: false,
      hasSellingPrice: false,
      hasPropertyType: false,
      loansLabel: ''
    },
    {
      type: 'rentalKeep',
      description: OldPropertyStatusMap.get('rentalKeep') as string,
      hasLoansAmount: false,
      hasSellingPrice: false,
      hasPropertyType: false,
      loansLabel: ''
    }
  ]
  public selectedOption: OldPropertyStatusProperty | null = null

  /**
   * Form getters for easy access
   */
  get hasCoApplicant(): boolean {
    return this.applicantsForm.controls.hasCoApplicant.value
  }

  get futurePropertyOwnership(): TFuturePropertyOwnership | null {
    return this.form.controls.oldProperty.controls.futurePropertyOwnership.value
  }

  ngOnInit() {
    // Whenever the new property type changes we need to check if it's
    // Bostadsrätt to add validators to fee
    this.form.controls.newProperty.controls.type.valueChanges
      .subscribe((value: PropertyType) => {
        FormUtils.addOrRemoveValidation(
          value === PropertyType.BOSTADSRATT,
          this.form.controls.newProperty.controls.fee,
          [Validators.required, Validators.min(1)]
        )
      })

    // Whenever the old property status changes we need to update the next
    // fields' validators
    this.form.controls.oldProperty.controls.futurePropertyOwnership.valueChanges
      .subscribe((value) => {
        // Set selected option to be used in template
        this.selectedOption = value !== null ? this.options.find(o => o.type === value)! : null

        const controls = this.form.controls.oldProperty.controls

        // Remove validators from all fields
        controls.loansAmount.clearValidators()
        controls.sellingPrice.clearValidators()
        controls.type.clearValidators()
        controls.fee.clearValidators()

        // if it is a HYRES, we set property type here
        if (this.form.controls.oldProperty.controls.futurePropertyOwnership.value?.includes('rental')) {
          controls.type.setValue(PropertyType.HYRES)
          controls.fee.setValue(0)
        } else {
          // Else we reset values
          controls.type.setValue(PropertyType.NONE)
          controls.fee.setValue(null)
        }

        // Reset all other values
        controls.loansAmount.setValue(null)
        controls.sellingPrice.setValue(null)

        switch (value) {
          case 'noSell':
            break
          case 'confirmedContract':
            // Add validators to "loansAmount" and "sellingPrice"
            FormUtils.addOrRemoveValidation(true, controls.loansAmount,
              [Validators.required, Validators.min(1)])
            FormUtils.addOrRemoveValidation(true, controls.sellingPrice,
              [Validators.required, Validators.min(1)])
            break
          case 'intendToSell':
            // Add validators to "loansAmount", "sellingPrice" and "type"
            FormUtils.addOrRemoveValidation(true, controls.loansAmount,
              [Validators.required, Validators.min(1)])
            FormUtils.addOrRemoveValidation(true, controls.sellingPrice,
              [Validators.required, Validators.min(1)])
            FormUtils.addOrRemoveValidation(true, controls.type,
              [Validators.required, Validators.min(0)])
            break
          case 'keepingProperty':
            // Add validators to "loansAmount", and "type"
            FormUtils.addOrRemoveValidation(true, controls.loansAmount,
              [Validators.required, Validators.min(1)])
            FormUtils.addOrRemoveValidation(true, controls.type,
              [Validators.required, Validators.min(0)])
            break
          case 'rentalKeep':
            // Add validators to "fee"
            FormUtils.addOrRemoveValidation(true, controls.fee,
              [Validators.required, Validators.min(1)])
            break
        }
      })

    // Whenever the old property type changes we need to check if it's
    // Bostadsrätt to add validators to fee
    this.form.controls.oldProperty.controls.type.valueChanges
      .subscribe((value: PropertyType) => {
        FormUtils.addOrRemoveValidation(
          value === PropertyType.BOSTADSRATT,
          this.form.controls.oldProperty.controls.fee,
          [Validators.required, Validators.min(1)]
        )
      })
  }

  /**
   * Calculates the down payment whenever its value is not valid. Called on change of the amount
   */
  public calculateDownPayment(): void {
    const price = this.form.controls.newProperty.controls.price.value as number

    // Only set Down payment if it has error or is empty, so that user's input is not modified after silly blurs
    if (!this.form.controls.newProperty.controls.downPayment.value ||
      this.form.controls.newProperty.hasError('downPayment')) {
      this.form.controls.newProperty.controls.downPayment.setValue(Math.ceil(price * DOWN_PAYMENT_MINIMUM_PERCENTAGE))
    }
  }
}
