import {DecimalPipe} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {
  FormatNumberDirective,
  PersonnummerValidatorDirective
} from '@sparbanken-syd/sparbanken-syd-theme'
import {filter, Subscription} from 'rxjs'
import {FormUtils} from '../../utils/form.utils'

export const getApplicantsForm = () => new FormGroup(({
  hasCoApplicant: new FormControl(false, {nonNullable: true}),
  areApplicantsMarried: new FormControl(false, {nonNullable: true}),
  numberOfChildren: new FormControl(null, Validators.required),
  hasCar: new FormControl(null, Validators.required),
  numberOfCars: new FormControl(null),
  hasLeaseCar: new FormControl(null, Validators.required),
  leaseCarCost: new FormControl(null),
  hasChildrenCareCost: new FormControl(null, Validators.required),
  monthlyChildrenCareCost: new FormControl(null),
  applicants: new FormArray<FormGroup>([getApplicantForm()]),
  children: new FormArray<FormGroup>([])
} as IApplicantsForm))

export const getApplicantForm = () => {
  const newApplicant = new FormGroup(({
    personalNumber: new FormControl('', {
      nonNullable: true,
      validators: Validators.required
    }),
    isMarried: new FormControl(false, {nonNullable: true}),
    partnerPersonalNumber: new FormControl(null)
  } as IApplicantGroup))

  newApplicant.controls.isMarried.valueChanges
    .subscribe((value: boolean) => {
      FormUtils.addOrRemoveValidation(value, newApplicant.controls.partnerPersonalNumber, [Validators.required])
    })

  return newApplicant
}

export const getChildForm = (index: number): FormGroup<IChildGroup> => {
  return new FormGroup(({
    age: new FormControl(null, Validators.required),
    id: new FormControl(`${index + 1}`, {nonNullable: true})
  } as IChildGroup))
}

export interface IApplicantsForm {
  hasCoApplicant: FormControl<boolean>
  areApplicantsMarried: FormControl<boolean>
  numberOfChildren: FormControl<number | null>
  children: FormArray<FormGroup<IChildGroup>>
  hasCar: FormControl<boolean | null>
  numberOfCars: FormControl<number | null>
  hasLeaseCar: FormControl<boolean | null>
  leaseCarCost: FormControl<number | null>
  hasChildrenCareCost: FormControl<boolean | null>
  monthlyChildrenCareCost: FormControl<number | null>
  applicants: FormArray<FormGroup<IApplicantGroup>>
}

export interface IApplicantGroup {
  personalNumber: FormControl<string>
  isMarried: FormControl<boolean>
  partnerPersonalNumber: FormControl<string | null>
}

export interface IChildGroup {
  age: FormControl<number | null>
  id: FormControl<string>
}

@Component({
  selector: 'spb-lanelafte-step-1',
  templateUrl: './lanelafte-step-1.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss'],
  imports: [ReactiveFormsModule, MatCheckbox, MatFormField, MatInput, MatError, MatLabel, MatSelect, MatOption, DecimalPipe, PersonnummerValidatorDirective, FormatNumberDirective]
})
export class LanelafteStep1Component implements OnInit {
  @Input() form = new FormGroup<IApplicantsForm>(({} as IApplicantsForm))

  /**
   * Selection range for how old a child is. Age 0 must be 0.1 to work (and then rounded in template).
   */
  public ages: number[] = [0.1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]

  /**
   * Form getters for easy access
   */
  get applicants(): FormGroup<IApplicantGroup>[] {
    return this.form.controls.applicants.controls
  }

  get children(): FormGroup<IChildGroup>[] {
    return this.form.controls.children.controls
  }

  get hasCoApplicant(): boolean {
    return this.form.controls.hasCoApplicant.value
  }

  get applicantPersonalNumber(): string | undefined {
    return this.applicants[0]?.controls.personalNumber.value
  }

  get coApplicantPersonalNumber(): string | undefined {
    return this.applicants[1]?.controls.personalNumber.value
  }

  ngOnInit() {
    this.form.controls.hasCoApplicant.valueChanges.subscribe((value: boolean) => {
      if (value) {
        this.form.controls.applicants.push(getApplicantForm())
      } else {
        this.form.controls.applicants.removeAt(1)
      }
    })

    // Whenever 'areApplicantsMarried' flag is checked the partner personnummer
    // should be set to each other
    let applicantsMarriedSubs$: Subscription[] = []
    this.form.controls.areApplicantsMarried.valueChanges
      .subscribe((value: boolean) => {
        if (value) {
          // Whenever we are here (applicants are married) both applicant form
          // controls are present so no need to check
          const applicantControls = this.form.controls.applicants.controls[0].controls
          const coApplicantControls = this.form.controls.applicants.controls[1].controls

          // Set 'isMarried' flag for both applicants
          applicantControls.isMarried.setValue(true)
          coApplicantControls.isMarried.setValue(true)

          // Set partners personnummers (applicant's partner is the co-applicant, and vice versa)
          applicantControls.partnerPersonalNumber.setValue(coApplicantControls.personalNumber.value)
          coApplicantControls.partnerPersonalNumber.setValue(applicantControls.personalNumber.value)

          // In case the values are not set in either of the personnummers, we
          // need to subscribe to changes to update their partners.
          // We save the subscriptions to remove them if "applicantsAreMarried"
          // flag changes and be able to unsubscribe.
          applicantsMarriedSubs$.push(
            applicantControls.personalNumber.valueChanges
              .pipe(
                filter(() => applicantControls.personalNumber.valid)
              )
              .subscribe((value) => {
                coApplicantControls.partnerPersonalNumber.setValue(value)
              })
          )
          applicantsMarriedSubs$.push(
            coApplicantControls.personalNumber.valueChanges
              .pipe(
                filter(() => coApplicantControls.personalNumber.valid)
              )
              .subscribe((value) => {
                applicantControls.partnerPersonalNumber.setValue(value)
              })
          )
        } else {
          // Reset values to default ones
          this.form.controls.applicants.controls.forEach(control => {
            control.controls.isMarried.setValue(false)
            control.controls.partnerPersonalNumber.setValue(null)
          })

          // Remove subscriptions for applicants married to each other
          applicantsMarriedSubs$.forEach(s => s.unsubscribe())
          applicantsMarriedSubs$ = []
        }
      })

    this.form.controls.numberOfChildren.valueChanges
      .subscribe((value: number | null) => {
        FormUtils.modifyNumberOfGroups(
          this.form.controls.children,
          value !== null ? value : 0,
          getChildForm
        )
      })

    this.form.controls.hasCar.valueChanges
      .subscribe((value: boolean | null) => {
        FormUtils.addOrRemoveValidation(value === true, this.form.controls.numberOfCars, [Validators.required])
      })

    this.form.controls.hasLeaseCar.valueChanges
      .subscribe((value: boolean | null) => {
        FormUtils.addOrRemoveValidation(
          value === true,
          this.form.controls.leaseCarCost,
          [Validators.required, Validators.min(0.1)]
        )
      })

    this.form.controls.hasChildrenCareCost.valueChanges
      .subscribe((value: boolean | null) => {
        FormUtils.addOrRemoveValidation(
          value === true,
          this.form.controls.monthlyChildrenCareCost,
          [Validators.required, Validators.min(0.1)]
        )
      })
  }
}
