import {StepperSelectionEvent} from '@angular/cdk/stepper'
import {Directive, HostListener} from '@angular/core'
import {MatStepper} from '@angular/material/stepper'

@Directive({
  selector: '[spbStepperScroll]'
})
export class StepperScrollDirective {

  constructor(private stepper: MatStepper) {
  }

  @HostListener('selectionChange', ['$event'])
  selectionChanged(selection: StepperSelectionEvent) {
    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1)
    this.scrollElement(stepId)
  }

  private scrollElement(elemId: string): void {
    const stepElement = document.getElementById(elemId)
    stepElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center'
    })
  }
}
