import {KeyValuePipe} from '@angular/common'
import {Component, Input, OnInit} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {OccupationType} from '@sparbanken-syd/loan-backend'
import {OccupationTypeMap} from '@sparbanken-syd/loan-backend/dist/shared'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {FormUtils} from '../../utils/form.utils'
import {IApplicantsForm} from '../lanelafte-step-1/lanelafte-step-1.component'

export const getApplicantIncomesForm = () => new FormGroup(({
  applicantIncomes: new FormArray<FormGroup>([getApplicantIncomeForm()])
} as IApplicantIncomeForm))

export const getApplicantIncomeForm = (): FormGroup<IApplicantIncomeGroup> => {
  const employerValidators: ValidatorFn[] = [Validators.required, Validators.minLength(3)]

  const newApplicantIncome = new FormGroup<IApplicantIncomeGroup>({
    monthlyIncome: new FormControl(null, [Validators.required, Validators.min(0.1)]),
    occupation: new FormControl(OccupationType.PERMANENT, {nonNullable: true}),
    // Since occupation's default value is PERMANENT. 'Employer' will have validators by default
    employer: new FormControl('', employerValidators),
    isAKassaMember: new FormControl(false, {nonNullable: true})
  })

  newApplicantIncome.controls.occupation.valueChanges
    .subscribe((value: OccupationType) => {
      FormUtils.addOrRemoveValidation(
        [OccupationType.HOURLY, OccupationType.PERMANENT, OccupationType.TIME_LIMITED].includes(value),
        newApplicantIncome.controls.employer,
        employerValidators
      )
    })

  return newApplicantIncome
}

export interface IApplicantIncomeForm {
  applicantIncomes: FormArray<FormGroup<IApplicantIncomeGroup>>
}

export interface IApplicantIncomeGroup {
  monthlyIncome: FormControl<number | null>
  occupation: FormControl<OccupationType>
  employer: FormControl<string | null>
  isAKassaMember: FormControl<boolean>
}

@Component({
  selector: 'spb-lanelafte-step-2',
  templateUrl: './lanelafte-step-2.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatInput, MatSelect, MatOption, MatCheckbox, KeyValuePipe, FormatNumberDirective]
})
export class LanelafteStep2Component implements OnInit {
  @Input() form = new FormGroup<IApplicantIncomeForm>(({} as IApplicantIncomeForm))

  @Input() applicantsForm = new FormGroup<IApplicantsForm>(({} as IApplicantsForm))

  readonly occupationTypes = OccupationTypeMap

  /**
   * Form getters for easy access
   */
  get applicantIncomes(): FormGroup<IApplicantIncomeGroup>[] {
    return this.form.controls.applicantIncomes.controls
  }

  ngOnInit() {
    this.applicantsForm.controls.applicants.valueChanges
      .subscribe((applicants) => {
        FormUtils.modifyNumberOfGroups(
          this.form.controls.applicantIncomes,
          applicants.length,
          getApplicantIncomeForm
        )
      })
  }

  public isEmployerRequired(occupation: OccupationType) {
    return [OccupationType.HOURLY, OccupationType.PERMANENT, OccupationType.TIME_LIMITED].includes(occupation)
  }

}
