import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper'
import {registerLocaleData} from '@angular/common'
import {provideHttpClient} from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer
} from '@angular/core'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {BrowserModule} from '@angular/platform-browser'
import {
  provideAnimationsAsync
} from '@angular/platform-browser/animations/async'
import {
  provideRouter,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router'
import {of} from 'rxjs'
import {routes} from './app.routes'
import {LOCAL_STORAGE, WINDOW} from './application/app'
import {LoanService} from './services/loan.service'

registerLocaleData(localeFr, 'fr')

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withHashLocation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'disabled'
      })
    ),
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true}
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'never',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    importProvidersFrom(BrowserModule),
    {provide: WINDOW, useValue: window},
    {
      provide: LOCAL_STORAGE,
      useFactory: () => localStorage
    },
    provideAppInitializer(() => {
      const initializerFn = ((service: LoanService) => () => of(service.isLoggedIn()))(inject(LoanService))
      return initializerFn()
    }),
    provideHttpClient(),
    provideAnimationsAsync()
  ]
}