<div class="spb-holder">
  <spb-header
    (preFillData)="preFillApplicationData($event)"
    (removeData)="removeApplicationData($event)"
  ></spb-header>

  <spb-breadcrumbs
    [links]="['https://www.sparbankensyd.se/lana-pengar/lanelofte/']"
    path="Låna | Lånelöfte"></spb-breadcrumbs>

  <div class="text-holder">
    <h1 class="mat-display-2">Ansökan lånelöfte</h1>
    <p>
      Vill du få svar på din ansökan direkt behöver du uppfylla kraven nedan och
      legitimera dig med BankID. Vi
      följer alltid upp din ansökan med en personlig kontakt.
    </p>
    <p>
      Du kan ansöka och få svar direkt om du/ni
    </p>
    <ul>
      <li>ska köpa villa, bostadsrätt eller fritidshus.</li>
      <li>är högst två personer som vill låna och ni tillhör samma hushåll efter
        köpet.
      </li>
      <li>har en tillsvidareanställning eller är pensionär.</li>
      <li>har en inkomst i svenska kronor.</li>
      <li>inte behöver finansiering av både befintligt och nytt boende
        samtidigt
      </li>
    </ul>
    <p>
      Om du inte uppfyller kraven, eller om du hellre vill att en av våra
      rådgivare går igenom och tar beslut om din
      ansökan, kan du ändå ansöka. Du får då svar inom 24 timmar (gäller
      bankdagar).
    </p>
    <p>Har du redan ansökt och fått ett lånelöfte kan du hämta det <a
      [routerLink]="['/lanelofte/lanelofte-fetch']">här.</a>
    </p>
  </div>

  <div class="text-holder step-holder mat-typography">
    <mat-vertical-stepper linear="true" spbStepperScroll>
      <ng-template #buttonsTemplate>
        <div class="buttons">
          <button mat-raised-button matStepperPrevious type="button">Tillbaka
          </button>
          <button color="primary" mat-raised-button matStepperNext
                  type="button">Nästa
          </button>
        </div>
      </ng-template>

      <mat-step [stepControl]="applicantsForm" label="Sökande och hushåll">
        <spb-lanelafte-step-1 [form]="applicantsForm"></spb-lanelafte-step-1>
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
      </mat-step>

      <mat-step [stepControl]="applicantIncomesForm" label="Inkomster">
        <spb-lanelafte-step-2 [applicantsForm]="applicantsForm"
                              [form]="applicantIncomesForm"></spb-lanelafte-step-2>
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
      </mat-step>

      <mat-step [stepControl]="propertyForm" label="Bostad">
        <spb-lanelafte-step-3 [applicantsForm]="applicantsForm"
                              [form]="propertyForm"></spb-lanelafte-step-3>
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
      </mat-step>

      <mat-step [stepControl]="existentLoansForm" label="Existerande lån">
        <spb-lanelafte-step-4 [applicantsForm]="applicantsForm"
                              [form]="existentLoansForm"></spb-lanelafte-step-4>
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
      </mat-step>

      <mat-step [stepControl]="contactsForm" label="Kontaktuppgifter">
        <spb-lanelafte-step-5 [applicantsForm]="applicantsForm"
                              [form]="contactsForm"></spb-lanelafte-step-5>
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
      </mat-step>


      <mat-step [stepControl]="otherInformationForm"
                label="Övriga upplysningar">
        <spb-lanelafte-step-6
          [form]="otherInformationForm"></spb-lanelafte-step-6>
        <ng-container [ngTemplateOutlet]="buttonsTemplate"></ng-container>
      </mat-step>

      <mat-step [stepControl]="termsForm" label="Skicka in">
        <spb-lanelafte-step-7
          (signSuccess)="onSignSuccess($event)"
          [applicantsForm]="applicantsForm"
          [errorMessage]="errorMessage"
          [form]="termsForm"
          [superFast]="superFast"
        ></spb-lanelafte-step-7>
      </mat-step>
    </mat-vertical-stepper>
  </div>
</div>
