<div class="spb-holder">
  <spb-header></spb-header>
  <spb-breadcrumbs
    [links]="['https://www.sparbankensyd.se/lana-pengar/lanelofte/', '/lanelofte']"
    path="Låna | Lånelöfte | Erbjudande">
  </spb-breadcrumbs>

  @if (loaded) {
    <div class="spb-plain-content">
      <div class="spb-holder">

        @if (result.status === 'pending' && result.reason!.includes('uc')) {
          <h1>Din ansökan om lånelöfte</h1>
          <p></p>
          <p>Utifrån hämtad kreditupplysning och det kreditregelverk vi
            tillämpar kan vi inte erbjuda dig sökt lånebelopp.</p>
          <p>Du är välkommen att kontakta oss om du vill att vi tittar närmare
            på din ansökan genom att skicka ett mejl med ditt namn och
            telefonnummer till <a href="mailto:digital@sparbankensyd.se">digital&#64;sparbankensyd.se</a>.
          </p>
          <p>Vänliga hälsningar <br>Sparbanken Syd</p>
        } @else if (result.status === 'pending') {
          <h1 class="mat-display-2">Tack
            för {{ result.coApplicant ? 'er' : 'din' }} ansökan!</h1>
          <p></p>
          <p>Vi återkommer till {{ result.coApplicant ? 'er' : 'dig' }} inom 24
            timmar (under kontorstid).</p>
          <p>Läs mer om våra erbjudanden genom att följa länkarna nedan.</p>
          <div class="button-holder">
            <a class="button"
               href="https://www.sparbankensyd.se/lana-pengar/lanelofte/">Lånelöfte</a>
            <a class="button"
               href="https://www.sparbankensyd.se/lana-pengar/bolan/">Bolån</a>
          </div>
          <p></p>
          <p>Vänliga Hälsningar</p>
          <p>Sparbanken Syd</p>
        } @else if (result.approved) {
          <ng-container>
            <h1>{{ result.coApplicant ? 'Er' : 'Din' }} ansökan om
              lånelöfte</h1>

            <p>Vi har glädjen att
              erbjuda {{ result.coApplicant ? 'er' : 'dig' }} ett lånelöfte på:
            </p>

            <div
              class="belopp">{{ result.approvedAmount | number : '1.0-0' : 'fr' }}
              kr
            </div>
            <p>Detta lånelöftet gäller
              till {{ result.validThru | date:'yyyy-MM-dd' }}</p>
            <p>Klicka på knappen nedan för att
              hämta {{ result.coApplicant ? 'ert' : 'ditt' }} lånelöfte.</p>
            <p>Behöver du hjälp att komma vidare så kontakta vår Kundservice på
              tel <a href="tel:+46411822000">0411-82 20 00</a>.</p>
            <button
              (click)="downloadDocument()"
              (keydown.enter)="downloadDocument()"
              (keydown.space)="downloadDocument()"
              class="spb-yellow-button"
            >
              Hämta lånelöfte
            </button>
            <button
              (click)="downloadLoanInfo()"
              (keydown.enter)="downloadLoanInfo()"
              (keydown.space)="downloadLoanInfo()"
              class="spb-yellow-button"
            >
              Hämta informationsbilaga
            </button>
          </ng-container>
        } @else {
          <ng-container>
            <h1>Din ansökan om lånelöfte</h1>
            @if (result.reason!.includes('uc')) {
              <p>Din ansökan är nu behandlad och vi kan inte erbjuda dig sökt
                lånebelopp.</p>
              <p>Du är välkommen att kontakta oss om du vill att vi tittar
                vidare på din ansökan genom att skicka ett mejl med ditt namn
                och telefonnummer till <a
                  href="mailto:digital@sparbankensyd.se">digital&#64;sparbankensyd.se</a>.
              </p>
            } @else if (result.reason!.includes('kalp')) {
              <p>Din ansökan är nu behandlad. Vi har räknat på uppgifterna du
                har lämnat och baserat på dem kan vi inte erbjuda dig sökt
                lånebelopp.</p>
              <p>Du är välkommen att kontakta oss om du t.ex. vill diskutera ett
                lägre lånebelopp genom att skicka ett mejl till <a
                  href="mailto:digital@sparbankensyd.se">digital&#64;sparbankensyd.se</a>.
              </p>
            } @else if (result.reason!.includes('input') ||
            result.reason!.includes('ucThreshold') ||
            result.reason!.includes('blancoLoansThreshold') ||
            result.reason!.includes('blancoLoansMatchingUC') ||
            result.reason!.includes('propertyLoansMatchingUC')) {
              <p>Din ansökan är nu behandlad.</p>
              <p>Vi kan inte lämna något svar direkt utan din ansökan behöver
                behandlas av en rådgivare. Du kommer bli kontaktad inom 24
                timmar (under kontorstid).</p>
            } @else {
              <p>Din ansökan är under behandling</p>
              <p>Du är välkommen att kontakta oss om du vill att vi tittar
                vidare på din ansökan genom att skicka ett mejl till <a
                  href="mailto:digital@sparbankensyd.se">digital&#64;sparbankensyd.se</a>.
              </p>
            }
            <p>Vänliga hälsningar <br>Sparbanken Syd</p>
          </ng-container>
        }
      </div>
      @if (showDebug) {
        <form [formGroup]="form">
          <mat-form-field subscriptSizing="dynamic">
            <mat-select formControlName="status" ngDefaultControl>
              <mat-option value="pending">Till handläggare</mat-option>
              <mat-option value="approved">Godkänt</mat-option>
              <mat-option value="manually_issued">Manuellt utfärdad</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-checkbox formControlName="approved" ngDefaultControl>Godkänd
          </mat-checkbox>
          <mat-checkbox formControlName="coApplicant" ngDefaultControl>
            Medsökande
          </mat-checkbox>
          @if (result.approved) {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Belopp</mat-label>
              <input formControlName="approvedAmount" matInput spbFormatNumber>
            </mat-form-field>
          } @else {
            <mat-form-field subscriptSizing="dynamic">
              <mat-label>Orsak</mat-label>
              <mat-select formControlName="reason" ngDefaultControl>
                <mat-option [value]="[undefined]">Behandling</mat-option>
                <mat-option value="kalp">KALP</mat-option>
                <mat-option value="uc">Nekad UC</mat-option>
                <mat-option value="input">Input skiljer mot UC</mat-option>
                <mat-option value="ucThreshold">UC för låg</mat-option>
                <mat-option value="blancoLoansThreshold">Blanco för hög
                </mat-option>
                <mat-option value="blancoLoansMatchingUC">Blancolån matchar ej
                  UC
                </mat-option>
                <mat-option value="propertyLoansMatchingUC">Bostadslån matchar
                  ej UC
                </mat-option>
              </mat-select>
            </mat-form-field>
          }
        </form>
      }
    </div>
  }
</div>