import {Component, Input, OnInit} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {FormUtils} from '../../utils/form.utils'
import {phoneValidator} from '../directives/phone/PhoneValidator'
import {IApplicantsForm} from '../lanelafte-step-1/lanelafte-step-1.component'

export const getContactsForm = () => new FormGroup(({
  contacts: new FormArray<FormGroup>([getContactForm()])
} as IContactsFrom))

export const getContactForm = () => new FormGroup(({
  fullName: new FormControl(null, Validators.required),
  email: new FormControl(null, [Validators.email, Validators.required]),
  phone: new FormControl(null, [Validators.required, phoneValidator])
} as IContactGroup))

export interface IContactsFrom {
  contacts: FormArray<FormGroup<IContactGroup>>
}

export interface IContactGroup {
  fullName: FormControl<string | null>
  email: FormControl<string | null>
  phone: FormControl<string | null>
}

@Component({
  selector: 'spb-lanelafte-step-5',
  templateUrl: './lanelafte-step-5.component.html',
  styleUrls: ['../lanelafte/lanelafte.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatInput]
})
export class LanelafteStep5Component implements OnInit {
  @Input() form = new FormGroup<IContactsFrom>(({} as IContactsFrom))

  @Input() applicantsForm = new FormGroup<IApplicantsForm>(({} as IApplicantsForm))

  /**
   * Form getters for easy access
   */
  get contacts(): FormGroup<IContactGroup>[] {
    return this.form.controls.contacts.controls
  }

  ngOnInit() {
    this.applicantsForm.controls.applicants.valueChanges
      .subscribe((applicants) => {
        FormUtils.modifyNumberOfGroups(
          this.form.controls.contacts,
          applicants.length,
          getContactForm
        )
      })
  }

}
