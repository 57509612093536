<form [formGroup]="form">
  <div class="mat-h3">Uppgifter om ny och befintlig bostad</div>

  <!-- New property -->
  <ng-container>
    <!-- Price -->
    <ng-container>
      <p class="lead-text">Tänkt pris kronor</p>
      <mat-form-field class="standard-full-width">
        <input
          (blur)="calculateDownPayment()"
          [formControl]="form.controls.newProperty.controls.price"
          inputmode="numeric"
          matInput
          placeholder="Tänkt pris kronor"
          spbFormatNumber
          type="text">
        <mat-error>{{ form.controls.newProperty.errors?.price }}</mat-error>
      </mat-form-field>
    </ng-container>

    <!-- Down payment -->
    <ng-container>
      <p class="lead-text">Kontantinsats, minst 15%</p>
      <mat-form-field [style.margin-bottom.px]="15" class="standard-full-width"
                      subscriptSizing="dynamic">
        <input
          [formControl]="form.controls.newProperty.controls.downPayment"
          inputmode="numeric"
          matInput
          placeholder="Kontantinsats kronor"
          spbFormatNumber
          type="text"
        >
        <mat-error>{{ form.controls.newProperty.errors?.downPayment }}</mat-error>
      </mat-form-field>
    </ng-container>

    <!-- Property type -->
    <ng-container>
      <p class="lead-text">Typ av bostad</p>
      <mat-form-field class="standard-full-width">
        <mat-select [formControl]="form.controls.newProperty.controls.type"
                    ngDefaultControl>
          @for (type of propertyTypes | keyvalue; track type.key) {
            <mat-option [value]="type.key">
              {{ type.value }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </ng-container>

    <!-- Property cost: only if "propertyType" is Bostadsrätt -->
    @if (form.controls.newProperty.controls.type.value === PropertyType.BOSTADSRATT) {
      <div>
        <p class="lead-text">Avgift kronor/månad</p>
        <mat-form-field class="standard-full-width">
          <input
            [formControl]="form.controls.newProperty.controls.fee"
            inputmode="numeric"
            matInput
            placeholder="Avgift kronor/månad"
            spbFormatNumber>
        </mat-form-field>
      </div>
    }
  </ng-container>

  <!-- Old property -->
  <ng-container>
    <!-- Future Property Ownership -->
    <ng-container>
      <p class="lead-text">Har {{ hasCoApplicant ? 'ni' : 'du' }} ett annat
        boende som ska behållas?</p>
      <mat-form-field class="standard-full-width">
        <mat-label>Välj i listan</mat-label>
        <mat-select
          [formControl]="form.controls.oldProperty.controls.futurePropertyOwnership"
          ngDefaultControl>
          <mat-option [disabled]="true" [value]="null">Välj i listan
          </mat-option>
          @for (option of options; track option.type) {
            <mat-option [value]="option.type">
              {{ option.description }}
            </mat-option>
          }
        </mat-select>

        <mat-error>{{
            form.controls.oldProperty.controls.futurePropertyOwnership.hasError('required')
              ? 'Du måste välja ett alternativ.' : ''
          }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container>
      @if (futurePropertyOwnership !== null) {
        @if (futurePropertyOwnership === 'confirmedContract') {
          <p>Observera att lånelöftet är villkorat av att du minst tre veckor
            innan utbetalning av nya lånet har ingått
            ett säljkontrakt avseende befintligt boende som inte är
            villkorat.</p>
        }

        <!-- Loans amount -->
        @if (selectedOption?.hasLoansAmount) {
          <ng-container>
            <p class="lead-text">{{ selectedOption?.loansLabel }}</p>
            <mat-form-field class="standard-full-width">
              <label>
                <input
                  [formControl]="form.controls.oldProperty.controls.loansAmount"
                  inputmode="numeric"
                  matInput
                  placeholder="Lånebelopp kronor"
                  spbFormatNumber>
              </label>
            </mat-form-field>
          </ng-container>
        }
        <!-- Selling price -->
        @if (selectedOption?.hasSellingPrice) {
          <ng-container>
            <p class="lead-text">{{ selectedOption?.sellingLabel }}</p>
            <mat-form-field class="standard-full-width">
              <label><input
                [formControl]="form.controls.oldProperty.controls.sellingPrice"
                inputmode="numeric"
                matInput
                placeholder="Försäljningspris kronor"
                spbFormatNumber>
              </label>
            </mat-form-field>
          </ng-container>
        }
        <!-- Property type -->
        @if (selectedOption?.hasPropertyType) {
          <ng-container>
            <!-- Property type -->
            <ng-container>
              <p class="lead-text">Typ av bostad</p>
              <mat-form-field class="standard-full-width">
                <mat-select
                  [formControl]="form.controls.oldProperty.controls.type"
                  ngDefaultControl>
                  <mat-option [value]="PropertyType.NONE" [disabled]="true">Välj
                    i
                    listan
                  </mat-option>
                  @for (type of propertyTypes | keyvalue; track type.key) {
                    <mat-option [value]="type.key">
                      {{ type.value }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </ng-container>

            <!-- Property cost: only if "propertyType" is Bostadsrätt -->
            @if (form.controls.oldProperty.controls.type.value === PropertyType.BOSTADSRATT) {
              <ng-container>
                <p class="lead-text">Avgift kronor/månad</p>
                <mat-form-field class="standard-full-width">
                  <input [formControl]="form.controls.oldProperty.controls.fee"
                         inputmode="numeric"
                         matInput
                         placeholder="Avgift kronor/månad"
                         spbFormatNumber
                         type="text">
                </mat-form-field>
              </ng-container>
            }
          </ng-container>
        }
        <!-- HYRES cost: only if "futurePropertyOwnership" is rentalKeep -->
        @if (form.controls.oldProperty.controls.futurePropertyOwnership.value === 'rentalKeep') {
          <ng-container>
            <p class="lead-text">Hyra kronor/månad</p>
            <mat-form-field class="standard-full-width">
              <input [formControl]="form.controls.oldProperty.controls.fee"
                     inputmode="numeric"
                     matInput
                     placeholder="Hyra kronor/månad"
                     spbFormatNumber
                     type="text">
            </mat-form-field>
          </ng-container>
        }
      }
    </ng-container>
  </ng-container>
</form>