<form [formGroup]="form">
  <div class="mat-h3">Existerande lån och krediter</div>
  <p class="lead-text">
    Har {{ hasCoApplicant ? 'ni' : 'du' }} studielån? Ange hushållets kostnad i
    kronor<strong>/månad</strong>
  </p>

  <mat-form-field class="standard-full-width" subscriptSizing="dynamic">
    <input
      [formControl]="form.controls.studentLoan"
      inputmode="numeric"
      matInput
      placeholder="Studielån kronor/månad"
      spbFormatNumber
      type="text">
    @if (form.controls.studentLoan.hasError('max')) {
      <mat-error>
        Beloppet är för stort, ange kostnad för hushållet per
        <strong>månad</strong>
      </mat-error>
    }
  </mat-form-field>

  <mat-checkbox
    [formControl]="form.controls.hasOtherLoans"
    color="primary"
    ngDefaultControl
  >
    Har {{ hasCoApplicant ? 'ni' : 'du' }} andra lån och krediter, t ex billån
    och krediter kopplade till kort? Ange
    alla {{ hasCoApplicant ? 'era' : 'dina' }} lån.
  </mat-checkbox>

  <p></p>

  @if (form.controls.hasOtherLoans.value) {
    <div class="loans-container">
      @for (loan of otherLoans; track $index) {
        <div>
          <p class="lead-text">Lånebelopp i kronor</p>
          <mat-form-field class="standard-full-width">
            <input
              [formControl]="loan.controls.amount"
              inputmode="numeric"
              matInput
              placeholder="Lånebelopp kronor"
              spbFormatNumber
              type="text"
            />
          </mat-form-field>

          <p class="lead-text">Typ av lån</p>
          <mat-form-field class="standard-full-width">
            <mat-select
              [formControl]="loan.controls.type"
              ngDefaultControl
              placeholder="Typ av lån">

              @for (type of loanTypes | keyvalue; track $index) {
                <!-- Exclude: NONE (-1) & HOUSING (0) -->
                @if (type.key > 0) {
                  <mat-option [value]="type.key">{{ type.value }}</mat-option>
                }
              }
            </mat-select>
          </mat-form-field>

          <div class="loan-buttons">
            @if ($count > 1) {
              <a (click)="removeLoan($index)"
                 (keydown.enter)="removeLoan($index)"
                 (keydown.space)="removeLoan($index)"
                 class="cursor-pointer"
                 tabindex="0">
                Ta bort rad
              </a>
            }
            @if ($last) {
              <a (click)="addLoan()"
                 (keydown.enter)="addLoan()"
                 (keydown.space)="addLoan()"
                 class="cursor-pointer"
                 tabindex="0">
                Lägg till rad
              </a>
            }
          </div>
        </div>
      }
    </div>
  }
</form>