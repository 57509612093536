import {DatePipe, DecimalPipe} from '@angular/common'
import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {
  IApplicationResult,
  TApplicationStatus,
  TApplicationStatusReason
} from '@sparbanken-syd/loan-backend'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {switchMap} from 'rxjs'
import {startWith} from 'rxjs/operators'
import {environment} from '../../../environments/environment'
import {WINDOW} from '../../application/app'
import {
  BreadcrumbsComponent
} from '../../common/breadcrumbs/breadcrumbs.component'
import {HeaderComponent} from '../../common/header/header.component'
import {
  WaitDialogComponent
} from '../../common/wait-dialog/wait-dialog.component'
import {LoanService} from '../../services/loan.service'

@Component({
  selector: 'spb-lanelofte-res',
  templateUrl: './lanelofte-res.component.html',
  styleUrls: ['./lanelofte-res.component.scss'],
  imports: [HeaderComponent, BreadcrumbsComponent, ReactiveFormsModule, MatFormField, MatSelect, MatOption, MatCheckbox, MatLabel, MatInput, DecimalPipe, DatePipe, FormatNumberDirective]
})
export class LanelofteResComponent implements OnInit {

  public result: IApplicationResult = {
    status: 'pending',
    approved: true,
    approvedAmount: 1000 * 1000,
    validThru: Date.now() + 60 * 60 * 24 * 30.6 * 1000 * 6,
    coApplicant: false,
    reason: ['kalp']
  }

  public showDebug = environment.showDebug

  public loaded = false
  /**
   * Form only visible if debug is set
   */
  public form = new FormGroup({
    status: new FormControl<TApplicationStatus>('approved', {nonNullable: true}),
    approved: new FormControl<boolean>(false, {nonNullable: true}),
    approvedAmount: new FormControl<number>(0, {nonNullable: true}),
    reason: new FormControl<TApplicationStatusReason[] | undefined>(undefined),
    coApplicant: new FormControl<boolean>(false, {nonNullable: true})
  })

  constructor(
    private loanService: LoanService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  public ngOnInit(): void {
    this.form.valueChanges
      .pipe(startWith(this.result))
      .subscribe({
        next: (val) => Object.assign(this.result, val)
      })

    const ref = this.dialog.open(WaitDialogComponent, {
      data: 'Hämtar lånelöfte...'
    })
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          const id = params.get('id') as string
          return this.loanService.getResult(id)
        })
      ).subscribe({
        next: (loanPromise: IApplicationResult) => {
          this.loaded = true
          ref.close()
          this.form.patchValue(loanPromise)
          this.result = loanPromise
        },
        error: () => {
          ref.close()
          this.router.navigate(['lanelofte']).then()
        }
      }
    )
  }

  public downloadDocument(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.loanService.getDocumentLink(params.get('id') as string))
    ).subscribe({
      next: (documentData) => this.injectedWindow.open(documentData.url)
    })
  }

  public downloadLoanInfo() {
    const link = document.createElement('a')
    link.href = 'assets/Produktblad - Beviljat lånelöfte.pdf'
    link.target = '_blank'
    link.click()
  }
}
