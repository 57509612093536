import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {PropertyType} from '@sparbanken-syd/loan-backend/dist/types'
import {EMPTY, Observable} from 'rxjs'
import {environment} from '../../environments/environment'

/**
 * A type for the received hemnet data.
 */
export interface IHemnetData {
  /**
   * The estimated price, or "utgångspris"
   * Could be a value edited by the user.
   */
  expectedPrice?: number | string

  /**
   * The down payment as entered by the user.
   */
  downPayment?: number | string
  /**
   * Like villa, bostadsrätt, fritidshus.
   */
  type?: PropertyType

  /**
   * If property type = bostadsrätt this is mandatory.
   */
  fee?: number
}

@Injectable({
  providedIn: 'root'
})
export class HemnetService {

  /**
   * Constructor for the win.
   */
  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * Highly opportunistic function for getting a hemnet cookie.
   */
  static GetCookie(): string | undefined {
    const cookies: string = document.cookie
    let hemnet
    if (!cookies) {
      // No cake no worry
      return
    }

    const cookieArray = cookies.split(' ')
    hemnet = cookieArray.find(cookie => cookie.indexOf('hemnet=') !== -1)

    if (hemnet) {
      const values = hemnet.split('=')
      hemnet = values[1].replace(/;/g, '')
    }
    return hemnet
  }

  /**
   * Get hemnet data
   */
  public getHemnetData(): Observable<IHemnetData> {
    const id = HemnetService.GetCookie()
    if (id) {
      const url = `${environment.hemnetServiceUrl}/loanpromise/${id}`
      return this.httpClient.get<IHemnetData>(url)
    }
    return EMPTY
  }
}
